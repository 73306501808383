import { styled } from '@mui/material'
import { createElement as $, FC, useEffect, useState } from 'react'
import utif from 'utif'

const InputImage: FC<{ url: string }> = ({ url }) => {
  const [src, setSrc] = useState('')
  useEffect(() => {
    if (url.startsWith('data:image/tif')) {
      const buffer = base64ToArrayBuffer(url.split(',')[1])
      const [ifd] = utif.decode(buffer)
      utif.decodeImage(buffer, ifd)
      const rgba  = utif.toRGBA8(ifd)
      const canvas = document.createElement('canvas')
      canvas.setAttribute('width', ifd.width.toString())
      canvas.setAttribute('height', ifd.height.toString())
      const context = canvas.getContext('2d')
      if (!context) return
      const clampedArray = new Uint8ClampedArray(rgba)
      const imageData = new ImageData(clampedArray, ifd.width)
      context.putImageData(imageData, 0,  0)
      const dataUrl = canvas.toDataURL('image/jpg')
      setSrc(dataUrl)
    }
    else setSrc(url)
  }, [url])
  return $(Img, { src })
}

function base64ToArrayBuffer(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const Img = styled('img')({
  width: '100%',
  display: 'block'
})

export default InputImage
