import { Button, styled } from '@mui/material'
import { createElement as $, FC, PropsWithChildren } from 'react'

const Upload: FC<PropsWithChildren<Props>> = ({ onChange, children }) => {
    // @ts-ignore
  return $(UploadButton, { component: 'label', variant: 'outlined' },
    children,
    $(Input, {
      hidden: true,
      accept: 'image/*',
      type: 'file',
      multiple: false,
      onChange: (event) => {
        const file = event.target.files?.[0]
        if (file) getBase64(file).then(onChange)
      }
    }))
}

const getBase64 = (file: File): Promise<string> => {
  var reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (reader.result)
        resolve(reader.result.toString())
    }
    reader.onerror = (error) => reject(error)
  })
}

const UploadButton = styled(Button)({
  padding: 0,
  overflow: 'hidden',
  display: 'block',
  minHeight: '3rem'
})

const Input = styled('input')({})

type Props = {
  onChange: (image: string) => void
}

export default Upload