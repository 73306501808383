import { Box, Button, Typography, styled } from '@mui/material'
import { createElement as $, FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { saveAs } from 'file-saver'

const Result: FC<{ output: Output }> = ({ output }) =>
  typeof output === 'string'
    ? $(Error, null, output)
    : $(Success, output)

const Error = styled(Typography)({
  fontFamily: 'monospace',
  whiteSpace: 'pre-wrap'
})

const Success: FC<SuccessOutput> = (props) =>  {
  const blob = new Blob([JSON.stringify(props.coords)], {type: "application/json;charset=utf-8"});
  return $(Box, { display: 'flex', flexDirection: 'column' },
    $(ResultImage, { src: getDataUrl('png', props.image) }),
    $(Box, { height: '1rem' }),
    $(Button, { variant: 'outlined', onClick: () => saveAs(blob, 'result.json') },
      $(FormattedMessage, { id: 'cell-finder.download' })))
}

const ResultImage = styled('img')({
  width: 356,
}, ({ theme }) => ({
  borderRadius: theme.shape.borderRadius
}))

export const getDataUrl = (format: string, base64: string) => `data:image/${format};base64,${base64}`

export type Output = SuccessOutput | string

type SuccessOutput = {
  image: string
  coords: [number, number][][]
}

export default Result