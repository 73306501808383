import IntlProvider from 'components/IntlProvider'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ThemedApp from './ThemedApp'
import RoutedApp from './RoutedApp'

const App: FC<PropsWithChildren> = () =>
  $(BrowserRouter, null,
    $(ThemedApp, null,
      $(IntlProvider, null,
        $(RoutedApp))))

export default App
