import messages from 'lang/ru.json'
import { createElement as $, FC, PropsWithChildren } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

const IntlProvider: FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const locale = 'ru'
  return $(ReactIntlProvider, {
    defaultLocale: locale,
    locale,
    messages,
    children
  })
}

export type MessageIds = keyof typeof messages

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: MessageIds
    }
  }
}

export default IntlProvider