import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery
} from '@mui/material'
import { createElement as $, FC, PropsWithChildren, useMemo } from 'react'

const ThemedApp: FC<PropsWithChildren> = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(() =>
    responsiveFontSizes(
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light'
        }
      })
    ),
  [prefersDarkMode])

  return $(ThemeProvider, { theme },
    $(CssBaseline, { enableColorScheme: true }),
    children)
}

export default ThemedApp