import { Box, CircularProgress, Fade, Slider, Typography, styled } from '@mui/material'
import { createElement as $, FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDebounce } from 'react-use'
import useLocalStorage from 'use-local-storage'
import InputImage from './InputImage'
import Result, { Output } from './Result'
import Upload from './Upload'

const CellFinder: FC = () => {
  const [loading, setLoading] = useState(false)
  const [output, setOutput] = useState<Output | string>()
  const [input, setInput] = useLocalStorage<Input>('cell-finder:input', {
    min_threshold: 150,
    max_threshold: 150,
    aperture_size: 3,
    roundness: 0.3,
    image: null
  })

  const onChange = (field: keyof Input) =>  ({
    value: input[field] as number,
    onChange: (event: Event, value: number | number[]) =>
      setInput({ ...input, [field]: value })
  })

  useDebounce(() => {
    if (input.image) {
      setLoading(true)
      sendRequest(input).then((output) => {
        setLoading(false)
        setOutput(output)
      })
    }
  }, 500, [input])

  return $(Box, { padding: '1rem' },
    $(Typography, { variant: 'h5' },
      $(FormattedMessage, { id: 'cell-finder.title' })),
    $(Box, { marginTop: '1rem', display: 'flex' },
      $(Box, { width: 356, flexShrink: 0 },
        $(Upload, { onChange: (image) => setInput({ ...input, image }) },
          input.image
            ? $(InputImage, { url: input.image }) // FIXME
            : $(Box, { width: 365, height: 256, display: 'flex', alignItems: 'center', justifyContent: 'center' },
                $(FormattedMessage, { id: 'cell-finder.upload' }))),
        $(Label, { variant: 'caption' },
          $(FormattedMessage, { id: 'cell-finder.parameters.min_threshold' })),
        $(Slider, {
          size: 'small',
          min: 0,
          max: 255,
          valueLabelDisplay: 'auto',
          ...onChange('min_threshold')
        }),
        $(Label, { variant: 'caption' },
          $(FormattedMessage, { id: 'cell-finder.parameters.max_threshold' })),
        $(Slider, {
          size: 'small',
          min: 0,
          max: 255,
          valueLabelDisplay: 'auto',
          ...onChange('max_threshold')
        }),
        $(Label, { variant: 'caption' },
          $(FormattedMessage, { id: 'cell-finder.parameters.aperture_size' })),
        $(Slider, {
          size: 'small',
          marks: true,
          min: 1,
          max: 7,
          step: 2,
          ...onChange('aperture_size')
        }),
        $(Label, { variant: 'caption' },
          $(FormattedMessage, { id: 'cell-finder.parameters.roundness' })),
        $(Slider, {
          size: 'small',
          min: 0,
          max: 1,
          step: 0.1,
          valueLabelDisplay: 'auto',
          ...onChange('roundness')
        })),
        $(Box, { width: '4rem', padding: '1rem' }, 
          loading && $(CircularProgress)),
        output &&
          $(Fade, { in: true, children:
            $(Box, null,
              $(Result, { output }))})))
}

const Label = styled(Typography)({
  marginTop: '1rem',
  display: 'block'
})

const sendRequest = async (input: Input) => {
  const payload = { ...input, image: input.image!.split(',')[1] }
  const url = `${process.env.REACT_APP_CELL_FINDER_URL}`
  const params = {
    method: 'post',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const response = await fetch(url, params)
  const json = await response.json()
  return response.status !== 200
    ? JSON.stringify(json)
    : json
}

type Input = {
  min_threshold: number,
  max_threshold: number,
  roundness: number
  aperture_size: number
  image: string | null
}

export default CellFinder